/* --------------- General ---------------*/
export const generalTypes = {
  IS_LOADING: 'IS_LOADING',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
};

/* --------------- Remission ---------------*/
export const remissionTypes = {
  GET_ALL_REMISSIONS: 'GET_ALL_REMISSIONS',
};

/* --------------- Session ---------------*/
export const sessionTypes = {
  LOGGED_IN: 'LOGGED_IN',
};

/* --------------- CTN ---------------*/
export const ctnTypes = {
  GET_ALL_CTN: 'GET_ALL_CTN',
};
/* --------------- Tariff ---------------*/
export const tariffTypes = {
  GET_ALL_TARIFFS: 'GET_ALL_TARIFFS',
};

/* --------------- Thresholds ---------------*/
export const thresholdTypes = {
  GET_ALL_THRESHOLDS: 'GET_ALL_THRESHOLDS',
};

/* --------------- Inbounds ---------------*/
export const inboundTypes = {
  GET_ALL_INBOUNDS: 'GET_ALL_INBOUNDS',
};

/* --------------- User ---------------*/
export const userTypes = {
  GET_USER_PARAMS: 'GET_USER_PARAMS',
  GET_ALL_USERS: 'GET_ALL_USERS',
};

/* --------------- Alliances ---------------*/
export const allianceTypes = {
  GET_ALLIANCE_PARAMS: 'GET_ALLIANCE_PARAMS',
  GET_ALL_ALLIANCES: 'GET_ALL_ALLIANCES',
};

/* --------------- Stores ---------------*/
export const storeTypes = {
  GET_STORE_PARAMS: 'GET_STORE_PARAMS',
  GET_ALL_STORES: 'GET_ALL_STORES',
};

/* --------------- Package Sizes ---------------*/
export const packageSizeTypes = {
  GET_ALL_PACKAGE_SIZES: 'GET_ALL_PACKAGE_SIZES',
};

/* --------------- Orders ---------------*/
export const orderTypes = {
  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
  GET_ORDER_DETAIL: 'GET_ORDER_DETAIL',
};

/* --------------- Locations ---------------*/
export const locationTypes = {
  GET_ALL_LOCATIONS: 'GET_ALL_LOCATIONS',
};
