export const routes = {
  home: '/',
  remissions: '/remisiones',
  login: '/login',
  authentication: '/auth',
  oauth: `https://idpdecathlon.oxylane.com/as/authorization.oauth2?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_API_URL}v1/auth/sso&scope=openid%20profile%20email`,
  ctn: '/ctn',
  tariff: '/tarifas',
  thresholds: '/umbrales',
  inbound: '/inbound',
  users: '/usuarios',
  alliances: '/alianzas',
  stores: '/centros',
  shipping: '/envios',
  packageSize: '/dimensiones',
  orders: '/ordenes',
  locations: '/ubicaciones',
};
