// Libraries
import {Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Layout} from 'antd';

// Store
import * as sessionSelectors from '../../store/redux/selectors/sessionSelectors';

// Components
import Login from '../session/Login';
import Users from '../users/Users';
import Remissions from '../remission/Remissions';
import CTN from '../ctn/CTN';
import Tariffs from '../tariffs/Tariffs';
import Thresholds from '../thresholds/Thresholds';
import Inbounds from '../inbounds/Inbounds';
import Alliances from '../alliances/Alliances';
import Stores from "../stores/Stores";
import PackageSizes from "../packageSizes/PackageSizes";
import Orders from "../orders/Orders";

// Utils
import {routes} from '../../utils/routes';
import {ROLES} from '../../utils/constants';
import {getLocalStorageItem} from '../../utils/functions';
import Locations from "../locations/Locations";

const {Content} = Layout;


function Router() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const renderRole = getLocalStorageItem('renderRole');
  const allowedModules = ROLES[renderRole as keyof typeof ROLES]?.allowedModules.map(module => module.name);

  return (
    <Content className={`flex flex-col w-0 flex-1 overflow-hidden min-h-screen ${isLoggedIn ? 'py-6 px-8' : ''}`}>
      <Routes>
        {isLoggedIn
          ?
          <>
            <Route path={routes.home} element={'Bienvenido al portal de logística'} />

            {allowedModules.includes(routes.remissions) &&
              <Route path={routes.remissions} element={<Remissions />} />}
            {allowedModules.includes(routes.ctn) &&
              <Route path={routes.ctn} element={<CTN />} />}
            {allowedModules.includes(routes.tariff) &&
              <Route path={routes.tariff} element={<Tariffs />} />}
            {allowedModules.includes(routes.thresholds) &&
              <Route path={routes.thresholds} element={<Thresholds />} />}
            {allowedModules.includes(routes.inbound) &&
              <Route path={routes.inbound} element={<Inbounds />} />}
            {allowedModules.includes(routes.shipping) &&
              <Route path={routes.shipping} element={'Envíos'} />}
            {allowedModules.includes(routes.alliances) &&
              <Route path={routes.alliances} element={<Alliances />} />}
            {allowedModules.includes(routes.stores) &&
              <Route path={routes.stores} element={<Stores />} />}
            {allowedModules.includes(routes.packageSize) &&
              <Route path={routes.packageSize} element={<PackageSizes />} />}
            {allowedModules.includes(routes.orders) &&
              <Route path={routes.orders} element={<Orders />} />}
            {allowedModules.includes(routes.locations) &&
              <Route path={routes.locations} element={<Locations />} />}
            {allowedModules.includes(routes.users) &&
              <Route path={routes.users} element={<Users />} />}

            <Route path={'*'}
                   element={<Navigate to={allowedModules[0]} />} />
          </>
          :
          <>
            <Route path={routes.authentication} element={<Login />} />
            <Route path={routes.login} element={<Login />} />

            <Route path={'*'} element={<Navigate to={routes.login} />} />
          </>
        }
      </Routes>
    </Content>
  );
}

export default Router;